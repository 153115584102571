<template>
  <div class="owner">
    <div class="catch-copy-container">
      <div
        class="catch-copy-sub container is-fluid"
        style="transform: translateY(30%); height: 100%"
      >
        <div style="font-size: 1.7rem; font-weight: 700; padding: 10px">
          入居中から退去後まで賃貸経営を手厚くサポート
        </div>
        <div
          style="
            font-size: 1rem;
            font-weight: 500;
            padding: 10px;
            text-align: center;
            overflow-wrap: normal;
          "
        >
          入居中の不備復旧工事・保険対応、退去時の精算業務や原状回復工事、空室物件のリフォーム・リノベーションから建物管理まで、手厚くサポートいたします。
        </div>
      </div>
    </div>
    <div style="padding: 3rem 0rem 3rem; background-color: #f5f5f5">
      <div class="container is-fluid">
        <div class="column is-full">
          <div
            style="font-size: 1.7rem; font-weight: 700; padding-bottom: 10px"
          >
            主な業務内容
          </div>
          <div style="padding-bottom: 30px">
            オーナー様の利益を第一に、賃貸経営のお手伝いをいたします
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../assets/taikyotachiai.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    退去立会い
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    賃貸借契約書をご確認させていただき、退去時の立会いから敷金精算業務、退去精算書の作成まで、不動産会社さまの代行として執り行います。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500">
                    [立会い・書類作成のみは手数料¥5,000]
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../assets/hokenkouji.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    工事の手配
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    <div>
                      原状回復、内装工事、電気・設備工事、水回り設備、リフォーム・リノベーション等、承ります。
                    </div>
                    <div>
                      内装工事（壁紙、襖、障子、床材等）及び外構工事（屋根、外壁等の塗装、駐車場舗装等）まで、幅広く施工いたします。
                    </div>
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../assets/YqcK9t.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    ハウスクリーニング
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    全体クリーニングから部分クリーニングまで(トイレ、浴室、窓、換気扇、エアコン等)施工いたします。
                    ワンルームから戸建てまで受け付けております。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../assets/claim-taiou.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    アフター(クレーム)対応
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    入居者さまからのアフター修理依頼(クレーム)は、退去につながってしまう重要な要件です。水回り、電気設備、内装の損傷など様々な事例がありますが、当社は自社施工ですので全てに対応可能です。専門知識のあるスタッフが迅速に現場へ伺い、施工まで対応いたします。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../assets/airconsenjo.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    エアコン洗浄
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    お掃除機能付きエアコンもお任せください
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../assets/naisoukouji.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    内装工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    壁紙、襖、障子、床材(フロアタイル等)施工いたします
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../assets/speed-taiou.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    電気・設備工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    電気設備、水回り設備、浴室リフォーム等承ります。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../assets/gaikoukouji.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    外構工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    屋根、外壁等の塗装、駐車場舗装等、施工いたします。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img src="./../../assets/zoen.png" alt="Placeholder image" />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    造園・庭木の剪定
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    草取り、庭木の剪定、抜根等お庭のこと全般承ります。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../assets/kanri.jpeg"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    建物管理
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    月２回の定期清掃、草取り、家賃管理等、一括借り上げのプランまで承ります。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: left; padding: 30px; padding: 1rem 1.5rem 1rem">
      <div
        class="columns"
        style="padding: 5rem 0rem 4rem; display: flex; align-items: center"
      >
        <div class="column is-full">
          <div
            style="font-size: 1.7rem; font-weight: 700; padding-bottom: 10px"
          >
            好循環を生むリフォーム
          </div>
          <div style="padding-bottom: 30px">
            魅力的なお部屋にすることで空室期間を短くし、オーナーさまの利益につなげます
          </div>
          <div style="padding: 1rem 0rem 1rem;background-color: #E3E3E0;">
            <div style="font-size: 1.25rem; font-weight: 700;padding-left:10px;padding-bottom:10px;">Before</div>
            <div style="height: 100%; width: 100%;text-align: center;">
              <img
                style="width: 100%;max-width: 600px;"
                src="./../../assets/owner-image-before.svg"
              />
            </div>
          </div>
          <div style="padding: 1rem 0rem 1rem;background-color: #DFF3FD;">
            <div style="font-size: 1.25rem; font-weight: 700;padding-left:10px;">After</div>
            <div style="orange;height:100%;width:100%;text-align: center;">
              <img
                style="width: 100%;max-width: 600px;"
                src="./../../assets/owner-image-after.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ToContactContent />
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import ToContactContent from '@/components/ToContactContent.vue';

const SystemModule = namespace("system");

@Options({
  components: {
    ToContactContent
  },
})
export default class Owner extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
    await this.updatePageLoadingState(false);
  }

  @SystemModule.Action("updatePageLoadingState")
  private updatePageLoadingState!: (state: boolean) => Promise<void>;

  private async routerView(to: string) {
    await this.updatePageLoadingState(true);
    this.$router.push(to);
  }
}
</script>
<style lang="scss" scoped>
.owner {
  padding-top: 3.25rem;

  .catch-copy-container {
    height: 50vh;
    background: linear-gradient(
          130deg,
          rgba(231, 223, 223, 0.6),
          rgba(64, 65, 41, 0.5)
        )
        fixed,
      url("./../../assets/chintai_2m.jpeg");
    // background: url("./../assets/YqcK9t.png");
    background-size: cover;
    background-position: center;
    text-align: center;
  }
}
</style>
